import React, { useState } from "react"
import * as S from "./header.styles.jsx"
import TopNav from "../top-nav/top-nav.component"
import CloseIcon from "@material-ui/icons/Close"
import MenuIcon from "@material-ui/icons/Menu"
import { useScrollTrigger, Slide } from "@material-ui/core"
import { useTheme } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const Header = () => {
  const scrollTrigger = useScrollTrigger({
    threshold: 70,
  })

  const [isActiveMenu, setIsActiveMenu] = useState(false)
  const theme = useTheme()

  const handleToggleMenu = () => {
    setIsActiveMenu(!isActiveMenu)
  }

  return (
    // <Slide appear={false} direction={`down`} in={!scrollTrigger}>
    <S.CustomAppBar
      isTransparent={!scrollTrigger}
      elevation={scrollTrigger ? 4 : 0}
    >
      <S.ItemContainer>
        <div className="logo">
          <a href="/" className="mark" aria-label="imData">
            <S.Logo />
          </a>
          <S.Tagline>Factory-Built Modular Data Centers</S.Tagline>
        </div>

        <TopNav isActive={isActiveMenu} />

        <S.MenuButton
          onClick={handleToggleMenu}
          size="medium"
          aria-label="Toggle Navigation"
        >
          {isActiveMenu ? <CloseIcon /> : <MenuIcon />}
        </S.MenuButton>
      </S.ItemContainer>
    </S.CustomAppBar>
    // </Slide>
  )
}
export default Header
